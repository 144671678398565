import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {GetEventsDeatils} from "../redux/actions/Events";
import Index from "../Components/PagesCompoents/GroupDeatils/Index";

const GroupDetails = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetEventsDeatils(parseInt(id)));
  }, [id]);



  return (
    <div className="mt-2">
      <Index id={id} />
    </div>
  );
};

export default GroupDetails;

