import React, { memo } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const CustomSliderbtn = ({prev ,next}) => {
  return (
    <div className="w-full flex gap-3 justify-end place-items-end px-2 my-3">
          <div
            className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
            onClick={prev}
          >
            <IoIosArrowBack className="text-[23px] hover:text-redColor" />
          </div>
          <div
            className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
            onClick={next}
          >
            <IoIosArrowForward className="text-[23px] hover:text-redColor" />
          </div>
        </div>
  )
}

export default memo(CustomSliderbtn)
