import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import "./FeaturedEvent.css";
import { Loading } from "notiflix";
import ShareModal from "./ShareModal";
import { FaShareAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { LuClipboardEdit } from "react-icons/lu";
import { useSelector } from "react-redux";
import instaLogo from "../../../assets/deafullogo.png";
import RedOverLay from "../../../assets/svg/VectorRed.svg";
import MainVerctor1 from "../../../assets/svg/VectorMain.svg";
import WhiteOverLay from "../../../assets/svg/Vectorwhite.svg";
import CreateEvent from "./CreateEvent";

const FeaturedEvent = ({ item, index }) => {
  const [Share, setShare] = useState(false);
  const [createevent, setcreateevent] = useState(false);
  const [event, setEvent] = useState({});
  const location = useLocation()

  const adminloading = useSelector((state) => state.Admin.adminloading);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();


  const handleNavigation = (eventId, dataToSend) => {
    navigate(`/tickets/group.details/${eventId}`, {
      state: { data: [dataToSend] },
    });
  };


  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [adminloading]);


  return (
    <>
      <div
        className={`LatestEventCardNewCardDesign
    ${index % 4 === 0 || index % 5 === 0
            ? "!bg-[rgba(0,0,0,.4)]"
            : index % 3 === 0
              ? "bg-white"
              : index % 2 === 0
                ? " bg-[#5C5C5C2B]"
                : "bg-[#270A0A]"
          }
    ${index % 4 === 0 ? "border-[1px] border-[rgba(255, 255, 255, 1)" : ""}
    ${index % 5 === 0 ? "border-[1px] border-[#8A0000]" : ""}
   
     px-[15px] py-[5px] flex justify-center gap-[15px] pt-[20px] place-items-center flex-col relative`}

      >
        <div className="relative ">
          <div className="relative !overflow-hidden mx-auto w-[275px] h-[304px]"
          >
            <img
              loading="lazy"
              src={MainVerctor1}
              alt=""
              className="px-[9px] !z-[25] "
            />
            <img
              loading="lazy"
              src={
                index % 6 === 0
                  ? RedOverLay
                  : index % 2 === 0
                    ? WhiteOverLay
                    : RedOverLay
              }
              alt=""
              className="!px-[9px] relative !z-[22] imageoverlay"
            />

          </div>
          <div
            className={` w-[40px] h-[28px] absolute top-[-4px] left-[10px] card_uper_vector1 z-[88]  `}
          >
            <FaShareAlt
              className={`text-[20px]  ${index % 2 === 0
                ? "text-[#8a0000]"
                : index % 3 === 0
                  ? "text-black"
                  : "text-[white]"
                } cursor-pointer`}
              onClick={() => setShare(!Share)}
            />
          </div>
          {user && user.email && user.role === "admin" && location?.pathname !== "/" && (
            <LuClipboardEdit
              className="absolute top-[50px] right-5 text-[25px] cursor-pointer text-[black] z-[88]"
              onClick={() => {
                setcreateevent(true);
                setEvent(item);
              }}
            />
          )}
          <div
            className={` ${index % 6 === 0
              ? "bg-[#8A0000]"
              : index % 4 === 0
                ? "bg-white"
                : index % 3 === 0
                  ? "bg-black"
                  : index % 2 === 0
                    ? "bg-white"
                    : "bg-[#8A0000]"
              }
          w-[51px] h-[51px] absolute right-[12px] bottom-[2px] card_bottom_vector1`}
          ></div>
          <img
            loading="lazy"
            src={instaLogo}
            alt=""
            className="absolute top-2 right-3 w-[61px] h-[36px]"
          />
          <div
            className={`w-[93%] py-2 z-50 bg-transparent min-h-[100px]
        absolute left-0 bottom-0 mx-[10px] pl-[6px]
        ${index % 2 === 0 ? "text-[black]" : "text-[white]"}
        `}
            onClick={() => handleNavigation(item?.id, item)}
          >
            <p className="Name_of_the_event mb-1 leading-tight">
              {item && item?.name && item?.name?.length > 30
                ? `${item?.name?.slice(0, 30)}...`
                : item?.name}
            </p>
            <p className="Time_stamp_event">
              {item &&
                item &&
                item?.occurs_at &&
                moment(item?.occurs_at)
                  .utcOffset("+01:00")
                  .format("h:mm A")
                  ?.split("+", 1)}
            </p>
            <p className="live_location_event uppercase !text-[14px]">
              LIVE IN -{" "}
              {item && item && item && item?.venue?.location?.slice(0, 16)}
            </p>
          </div>
        </div>
        {/* --- footer  */}
        <div
          className={`new_card_footer px-[3px] w-[100%] mt-[10px] flex justify-start place-items-start gap-2 ${index % 5 === 0
            ? "!text-white"
            : index % 4 === 0
              ? "!text-white"
              : index % 3 === 0
                ? "text-black"
                : "text-white"
            }`}
          onClick={() => handleNavigation(item?.id, item)}
        >
          {/* --- button  */}
          <button className="new_card_btn !text-white"
          onClick={()=>console.log(item?.occurs_at)}
          >Get Tickets</button>
          <div>
            <p className="time_text_of_new_card">Time</p>
            <p
              className={`time_of_new_card ${index === 5
                ? "bg-white"
                : index % 3 === 0
                  ? "!bg-black"
                  : "!bg-white"
                }
            
            ${index % 3 === 0 ? "!text-white" : "!text-black"}
            `}
            >
              {item &&
                item?.occurs_at &&
                // moment(item?.occurs_at).format("hh:mm A")}
                moment(item?.occurs_at)
                  .utcOffset("+01:00")
                  .format("h:mm A")
                  ?.split("+", 1)
              }
            </p>
          </div>
          <span className="w-[2px] h-[37px] bg-color2"></span>
          <div className="flex justify-start place-items-end gap-2">
            <p className="date_of_new_card">
              {item && item?.occurs_at_local && item?.occurs_at_local?.slice(8,10)}
            </p>
            <p className="month_of_new_card">
              {item && item?.occurs_at_local && moment(item?.occurs_at_local).format("MMM")}
            </p>
          </div>
        </div>
      </div>
      {/* ========== share modal  */}
      {Share && <ShareModal setShow={setShare} item={item} Share={Share} />}
      {/* ===  create event modal  */}
      {createevent && location?.pathname !== "/" && <CreateEvent event={event} setEvent={setEvent} setcreateevent={setcreateevent} />}
    </>
  );
};

export default memo(FeaturedEvent);
