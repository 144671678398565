import "../style/profile.css";
import { Loading } from "notiflix";
import { RxCross1 } from "react-icons/rx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiURL } from "../../../setting/GlobleVariable";
import { PrintOrder, UserOrdersFunc } from "../../../redux/actions/UserOrder";
import momment from "moment";

const AllOrders = () => {
  const [email, setemail] = useState("");
  const [showbtn, setShowBtn] = useState(false);
  const [showmoadl, setShowModal] = useState(false);


  const dispatch = useDispatch();
  const UserOrders = useSelector((state) => state.order.UserOrders);
  const IsLoading = useSelector((state) => state.order.isloading);


  const createPass = () => {
    document.getElementById("form").addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(`${ApiURL}/order/GetPassInGoogleWallet`, {
        method: "POST",
        body: `client_email=${email}`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          return res.text();
        })
        .then((button) => {
          document.getElementById("button").innerHTML = button;
          setShowBtn(true);
        });
    });
  };


  useEffect(() => {
    dispatch(UserOrdersFunc());
  }, []);

  useEffect(() => {
    if (IsLoading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [IsLoading]);

 
  return (
    <div className="py-5 px-5 w-[100%] min-h-[90vh]">
      <h2 className="my-3 font-bold text-[25px]">
        Total Tickets ({UserOrders?.length})
      </h2>
      {/* ---  */}

      <div className="max-w-[1000px] xl:max-w-[100%]">
        <div class=" shadow-md sm:rounded-lg order_table">
          <table class="w-full  text-sm text-left rtl:text-right border-[1px] border-color3 rounded-sm  dark:text-gray-400 !bg-color1 !text-color2">
            <thead class="text-xs text-gray-700 uppercase bg-redColor dark:bg-color3 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3 h_text">
                  OrderId
                </th>
                <th scope="col" class="px-6 py-3 h_text">
                  Ticket Type
                </th>
                {/* <th scope="col" class="px-6 py-3 h_text">
                  Status
                </th> */}
                <th scope="col" class="px-6 py-3 h_text">
                  Total Tickets
                </th>
                <th scope="col" class="px-6 py-3 h_text">
                  Toatl Amount
                </th>

                <th scope="col" class="px-6 py-3 h_text">
                  Payment Method
                </th>
                <th scope="col" class="px-6 py-3 h_text">
                  Purchased At
                </th>

                <th scope="col" class="px-6 py-3 h_text">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {UserOrders?.length > 0 ?
                UserOrders?.map((item, index) => {
                  return (
                    <tr
                      class={`!bg-color1  !text-color2 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600
                    
                    ${index % 2 !== 0 ? "selected_item" : "bg-color1"}
                    `}
                      key={index}
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium whitespace-nowrap dark:text-white"
                      >
                        #{item?.invoice_Id}
                      </th>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium whitespace-nowrap dark:text-white"
                      >
                        {item?.type}
                      </th>
                      {/* <td class="px-6 py-4">{item?.status}</td> */}
                      <td class="px-6 py-4">{item?.items?.[0].splits}</td>
                      <td class="px-6 py-4">${item?.totalAmount}</td>
                      <td class="px-6 py-4">{item?.payments}</td>
                      <td class="px-6 py-4">{momment(item?.createdAt)?.format('D MMM YYYY h:mm A')}</td>
                      <td class="flex items-center px-6 py-4">
                        <p
                          class="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
                          // onClick={() => setShowModal(true)}
                          onClick={() => {
                            dispatch(
                              PrintOrder(
                                item?.order_Id,
                                // item?.item_id
                                item?.items[0]?.ticket_group_id
                              )
                            );
                          }}
                        >
                          Get Invoice
                        </p>
                      </td>
                    </tr>
                  );
                })
                :
                <td colSpan={7}>
                  <p className="w-[100%] text-center py-[100px] text-[18px]">No tickets were found</p>
                </td>
              }
            </tbody>
          </table>
        </div>
      </div>
      {/* ===== modal  */}
      {showmoadl && (
        <div class="fixed z-[29] bg-color4 text-color2   payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-50 py-10">
          <div class="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4 text-color2">
            <div class="relative !min-h-[300px]">
              <div class="m-2 my-[30px] max-w-[450px] mx-auto">
                <RxCross1
                  className="absolute top-[-10px] right-6 text-[23px] cursor-pointer text-color2"
                  onClick={() => setShowModal(false)}
                />
                <form id="form">
                  <div className="!pt-4 flex justify-start place-items-start gap-1 flex-col">
                    <label htmlFor="" className="mx-2">
                      Enter Email Address
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      type="text"
                      placeholder="Email"
                      className="w-full bg-transparent text-color2 px-2 py-2 border-[1px] rounded-md"
                    />
                    {!showbtn && (
                      <button
                        onClick={createPass}
                        type="submit"
                        id="submit"
                        className="w-full my-3 bg-color1 py-3 rounded-md"
                      >
                        Create Pass
                      </button>
                    )}
                    {/* <img src="/wallet-button.png" alt="" /> */}
                    <div id="button"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllOrders;
