import { createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import {
  ALL_EVENTS_FILTERS_EVENTS_REQUEST,
  ALL_EVENTS_FILTERS_EVENTS_SUCCESS,
  CATEGORY_SEARCH_EVENTS_ERROR,
  CATEGORY_SEARCH_EVENTS_REQUEST,
  CATEGORY_SEARCH_EVENTS_REQUEST_FAIL,
  CATEGORY_SEARCH_EVENTS_SUCCESS,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  FILTERS_EVENTS_ERROR,
  FILTERS_EVENTS_REQUEST,
  FILTERS_EVENTS_REQUEST_FAIL,
  FILTERS_EVENTS_SUCCESS,
  GET_ALL_EVENTS_ERROR,
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_EVENTS_DEATILS_ERROR,
  GET_EVENTS_DEATILS_REQUEST,
  GET_EVENTS_DEATILS_SUCCESS,
  GET_EVENTS_GROUP_DEATILS_ERROR,
  GET_EVENTS_GROUP_DEATILS_REQUEST,
  GET_EVENTS_GROUP_DEATILS_SUCCESS,
  GET_SINGLE_EVENT_ERROR,
  GET_SINGLE_EVENT_REQUEST,
  GET_SINGLE_EVENT_SUCCESS,
  SEARCH_EVENTS_ERROR,
  SEARCH_EVENTS_REQUEST,
  SEARCH_EVENTS_REQUEST_FAIL,
  SEARCH_EVENTS_SUCCESS,
  SEARCH_FILTER_EVENTS_ERROR,
  SEARCH_FILTER_EVENTS_REQUEST,
  SEARCH_FILTER_EVENTS_REQUEST_FAIL,
  SEARCH_FILTER_EVENTS_SUCCESS,
} from "../constant/Events";
import {
  PRINT_ORDERS_ERROR,
  PRINT_ORDERS_FAIL,
  PRINT_ORDERS_REQUEST,
  PRINT_ORDERS_SUCCESS,
} from "../constant/Orders";

const initialState = {
  isLoading: false,
  AllEvents: [],
  singleEvent : {},
  EventDetails: [],
  CopyofAllEvents: [],
  GroupsEvents: {},
  serachevents: [],
  seracheventsCopy: [],
  copyofsearch: [],
  SibgleEventLoading: false,
  AllEventLoading: false,
  total_entries: null,
  Filterserachevents: [],
  TaxData : {}
};

export const EventReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_ALL_EVENTS_REQUEST, (state, action) => {
      state.Filterserachevents = [];
      state.isLoading = true;
      state.AllEventLoading = true;
    })
    .addCase(GET_ALL_EVENTS_SUCCESS, (state, action) => {
      state.total_entries = action.payload?.total_entries;
      const uniqueEventIds = new Set(state.AllEvents.map((event) => event.id));
      const newEvents = action.payload?.events?.filter(
        (event) => !uniqueEventIds.has(event.id)
      );
      state.AllEvents = [...state.AllEvents, ...newEvents];
      state.isLoading = false;
      state.AllEventLoading = false;
    })
    .addCase(GET_ALL_EVENTS_ERROR, (state, action) => {
      state.isLoading = false;
      state.AllEventLoading = false;
    })
    // ========== event details
    .addCase(GET_EVENTS_DEATILS_REQUEST, (state, action) => {
      state.isLoading = true;
    })
    .addCase(GET_EVENTS_DEATILS_SUCCESS, (state, action) => {
      state.isLoading = false;
      state.EventDetails = action.payload;
    })
    .addCase(GET_EVENTS_DEATILS_ERROR, (state, action) => {
      state.isLoading = false;
    })
    // --- grou details
    .addCase(GET_EVENTS_GROUP_DEATILS_REQUEST, (state, action) => {
      state.isLoading = true;
      state.SibgleEventLoading = true;
    })
    .addCase(GET_EVENTS_GROUP_DEATILS_SUCCESS, (state, action) => {
      state.isLoading = false;
      state.SibgleEventLoading = false;
      state.GroupsEvents = action.payload;
    })
    .addCase(GET_EVENTS_GROUP_DEATILS_ERROR, (state, action) => {
      state.isLoading = false;
      state.SibgleEventLoading = false;
    })
    // ---- CREATE ORDER
    .addCase(CREATE_ORDER_REQUEST, (state, action) => {
      state.isLoading = true;
    })
    .addCase(CREATE_ORDER_SUCCESS, (state, action) => {
      state.isLoading = false;
    })
    .addCase(CREATE_ORDER_ERROR, (state, action) => {
      state.isLoading = false;
    })
    // ----- search events
    .addCase(SEARCH_EVENTS_REQUEST, (state) => {
      state.isLoading = true;
    })
    .addCase(SEARCH_EVENTS_REQUEST_FAIL, (state) => {
      state.isLoading = false;
    })
    .addCase(SEARCH_EVENTS_SUCCESS, (state, action) => {
      state.serachevents = action.payload;
      state.isLoading = false;
    })
    .addCase(SEARCH_EVENTS_ERROR, (state, action) => {
      state.isLoading = false;
    })
    // -------- filter the events
    .addCase(FILTERS_EVENTS_REQUEST, (state) => {
      state.isLoading = true;
    })
    .addCase(FILTERS_EVENTS_REQUEST_FAIL, (state) => {
      state.isLoading = false;
    })
    .addCase(FILTERS_EVENTS_SUCCESS, (state, action) => {
      state.isLoading = false;
      state.copyofsearch = action.payload.filteredEvents1;
      state.serachevents = action.payload.filteredEvents;
    })
    .addCase(FILTERS_EVENTS_ERROR, (state) => {
      state.isLoading = false;
    })
    // -------- filter the events at event page
    .addCase(ALL_EVENTS_FILTERS_EVENTS_REQUEST, (state) => {
      state.isLoading = true;
    })

    .addCase(ALL_EVENTS_FILTERS_EVENTS_SUCCESS, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.CopyofAllEvents = action.payload.filteredEvents1;
      state.AllEvents = action.payload.filteredEvents;
    })

    // --- cleasr filter
    .addCase("CLEAR_FILTERS", (state, action) => {
      state.serachevents = action.payload;
      state.copyofsearch = [];
      state.CopyofAllEvents = [];
    })
    // --- cleasr filter
    .addCase("CLEAR_FILTERS_EVENTS", (state, action) => {
      state.AllEvents = action.payload;
      state.copyofsearch = [];
      state.CopyofAllEvents = [];
    })
    // --------- search by events
    .addCase(CATEGORY_SEARCH_EVENTS_REQUEST, (state) => {
      state.isLoading = true;
    })
    .addCase(CATEGORY_SEARCH_EVENTS_REQUEST_FAIL, (state) => {
      state.isLoading = false;
    })
    .addCase(CATEGORY_SEARCH_EVENTS_SUCCESS, (state, action) => {
      state.serachevents = action.payload;
      state.isLoading = false;
    })
    .addCase(CATEGORY_SEARCH_EVENTS_ERROR, (state, action) => {
      state.isLoading = false;
    })
    // ---- print order
    .addCase(PRINT_ORDERS_REQUEST, (state) => {
      state.isLoading = true;
    })
    .addCase(PRINT_ORDERS_FAIL, (state) => {
      state.isLoading = false;
    })
    .addCase(PRINT_ORDERS_SUCCESS, (state, action) => {
      state.isLoading = false;
    })
    .addCase(PRINT_ORDERS_ERROR, (state, action) => {
      state.isLoading = false;
    })
    // ------ search filter event
    .addCase(SEARCH_FILTER_EVENTS_REQUEST, (state, action) => {
      state.AllEvents = [];
      state.isLoading = true;
      state.AllEventLoading = true;
    })
    .addCase(SEARCH_FILTER_EVENTS_REQUEST_FAIL, (state, action) => {
      state.isLoading = false;
      state.AllEventLoading = false;
    })
    .addCase(SEARCH_FILTER_EVENTS_SUCCESS, (state, action) => {
      if (action.payload.IsSearch === true) {
        if (state.seracheventsCopy?.length > 0) {
          state.serachevents = state.seracheventsCopy
        }
        if(state.seracheventsCopy?.length === 0){
          state.seracheventsCopy = state.serachevents;
        }
        const newdata = state.serachevents?.filter((item) => {
          const itemDate = item?.occurs_at?.slice(0, 10);
          const startDate = action.payload.startDate;
          const endDate = action.payload.endDate;
          return itemDate.toString() >= startDate.toString() && itemDate.toString() <= endDate.toString();
        });
        state.serachevents = newdata;
        state.isLoading = false;
        state.AllEventLoading = false;
      } else {
        state.total_entries = action.payload?.data.total_entries;
        const uniqueEventIds = new Set(
          state.Filterserachevents.map((event) => event.id)
        );
        const newEvents = action.payload?.data?.events.filter(
          (event) => !uniqueEventIds.has(event.id)
        );
        state.Filterserachevents = [...state.Filterserachevents, ...newEvents];
        state.isLoading = false;
        state.AllEventLoading = false;
      }

    })
    .addCase(SEARCH_FILTER_EVENTS_ERROR, (state, action) => {
      state.isLoading = false;
      state.AllEventLoading = false;
    })
    // --- empty the event
    .addCase("EMPTY_SEARCH_EVENT_FILTER", (state, action) => {
      state.Filterserachevents = [];
    })

    // single event
    .addCase(GET_SINGLE_EVENT_REQUEST, (state) => {
      state.isLoading = true;
    })
    .addCase(GET_SINGLE_EVENT_SUCCESS, (state, action) => {
      state.isLoading = false;
      state.singleEvent = action.payload;
    })
    .addCase(GET_SINGLE_EVENT_ERROR, (state) => {
      state.isLoading = false;
    })

    // get tax data
    .addCase("GET_TAX_REQUEST", (state) => {
      state.isLoading = true;
    })
    .addCase("GET_TAX_SUCCESS", (state, action) => {
      state.isLoading = false;
      state.TaxData = action.payload;
    })
    .addCase("GET_TAX_ERROR", (state) => {
      state.isLoading = false;
    });
});
