
import "swiper/css";
import "./LatestEvent.css";
import "swiper/css/pagination";
import { useSelector } from "react-redux";
import EventCarosal from "./EventCarosal";
import "../TrendingEvents/styles/styles.css";
import React, { useRef, useMemo, useCallback, useState, useEffect } from "react";
import CustomSliderbtn from "./CustomSliderbtn";
import NoDataMessag01 from "../../NoDataMessage/NoDataMessag01";
import LatestLoading from "../../Loading/LatestLoading";

const LatestEvent = () => {
  const swiperRefs = [useRef(null), useRef(null), useRef(null)];
  const FeaturedEventsdata = useSelector((state) => state.Admin.featuredEvents);
  const isLoading = useSelector((state) => state.Admin.adminloading);
  const [isFilterLoading, setisFilterLoading] = useState(true)

  const filterTrendingEvent = useMemo(() => {
    return FeaturedEventsdata?.filter((item) =>
      item.isFeatured
    ) || [];
  }, [FeaturedEventsdata]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setisFilterLoading(false)
      }, 3000);
    }
  }, [filterTrendingEvent, isLoading])





  const goNext = useCallback((index) => {
    const swiper = swiperRefs[index]?.current?.swiper;
    if (swiper) swiper.slideNext();
  }, [swiperRefs]);

  const goPrev = useCallback((index) => {
    const swiper = swiperRefs[index]?.current?.swiper;
    if (swiper) swiper.slidePrev();
  }, [swiperRefs]);

  const renderEventCarousel = (start, end, index) => {
    const events = filterTrendingEvent.slice(start, end);
    if (events.length > 0) {
      return (
        <>
          <CustomSliderbtn prev={() => goPrev(index)} next={() => goNext(index)} />
          <EventCarosal swiperRef={swiperRefs[index]} events={events} />
        </>
      );
    }
    return null;
  };



  return (
    <div className="latest_event text-color2 bg-color1">
      <h2 className="latest_event_heading text-redColor text-center">
        LATEST TICKETS
      </h2>
      {
        isLoading || isFilterLoading ?
          <LatestLoading isHome={true} goNext={goNext} goPrev={goPrev} swiperRef={swiperRefs}/> :
          <>
            {renderEventCarousel(0, 12, 0)}
            {renderEventCarousel(12, 24, 1)}
            {
              filterTrendingEvent?.length > 0
                ? renderEventCarousel(24, 100, 2)
                : <NoDataMessag01 />
            }
          </>
      }

    </div>
  );
};

export default LatestEvent;
