import "./Index.css";
import Seatics from "./Seatics";
import Sidebar from "./Sidebar/Sidebar";
import Loadingcom from "../../Loading/Loading";
import noChart from "../../../assets/NoMap.gif";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GetSingleEventById } from "../../../redux/actions/Events";

const Index = ({ id }) => {
  const GroData = useSelector((state) => state.events.EventDetails)
  const AllEvents = useSelector((state) => state.events.AllEvents);
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  // seatics sidebar data testing 
  const [SeaticsData, setSeaticsData] = useState([])
  const [isImage, setIsImage] = useState("");
  const [filtertheEvent, setFiltertheEvent] = useState([]);
  const location = useLocation();
  const receivedData = location.state?.data;
  // const receivedData = [];
  const reviceImage = location?.state?.image;
  const dispatch = useDispatch();

  useEffect(() => {
    const seatingChartMedium =
      (filtertheEvent &&
        filtertheEvent[0]?.configuration?.seating_chart?.medium) ||
      (receivedData && receivedData[0]?.configuration?.seating_chart?.medium);
    const startSliceIndex = 31;
    const endSliceIndex = 39;

    if (seatingChartMedium) {
      const beforeSlice = seatingChartMedium.slice(0, startSliceIndex);
      const afterSlice = seatingChartMedium.slice(endSliceIndex);
      const remainingString = beforeSlice + afterSlice;
      setIsImage(remainingString);
    }
  }, [filtertheEvent]);


  // get parent event details by id
  useEffect(() => {
    if (id) {
      if (AllEvents?.length === 0 && filtertheEvent?.length === 0) {
        const fetchData = async () => {
          const result = await dispatch(GetSingleEventById(id));
          if (result) {
            setFiltertheEvent([result])
          }
        }
        fetchData();
      }
    }
  }, [id, dispatch , AllEvents]);



  return (
    <div className={`h-[100vh]  index_groupdeatails  `}>

        <div className="index_deatils  !bg-color1 !text-color2 h-[100vh]  overflow-y-hidden">
          {/* --- sidebar  */}
          <div className="!w-[400px] !h-[100%] !overflow-y-auto chactout_sidebar">
            <Sidebar
              filterevent={filtertheEvent}
              receivedData={receivedData}
              isImage={isImage}
              noChart={noChart}
              reviceImage={reviceImage}
              AllEventLoading={AllEventLoading}
              SeaticsData={SeaticsData}
              eventId={id}
              setSeaticsData={setSeaticsData}
            />
          </div>
          {/* --- content  */}
          <div className="w-full flex justify-center !text-color2 place-items-center h-[100%] py-5 px-5 ">
            <div className="parent_seat_map_wraper bg-color1  hide_image_div_in_mobile ">
              {AllEventLoading ? (
                <div>
                  <Loadingcom />
                </div>
              ) :
              <Seatics
                  setSidebarData={setSeaticsData}
                  SeaticsData={SeaticsData}
                  GroupsEvents={GroData}
                  event={filtertheEvent?.length === 1 ? filtertheEvent && filtertheEvent[0] : receivedData && receivedData[0]}
                />
              //  (filtertheEvent &&
              //   filtertheEvent[0]?.configuration?.seating_chart?.large ==
              //   "null") ||
              //   (receivedData &&
              //     receivedData[0]?.configuration?.seating_chart?.large ==
              //     "null") ? (
              //   <img src={noChart} alt="no seating chart" className=" h-auto" />
              // ) : (
              //   id == '2710569' ? <Seatics
              //     setSidebarData={setSeaticsData}
              //     SeaticsData={SeaticsData}
              //     GroupsEvents={GroData}
              //   /> :
              //     <img
              //       src={isImage ? isImage : noChart}
              //       alt="Seating Chart Image"
              //       className=" h-auto bg-color1 bg-opacity-0"
              //     />
              // )
              }
            </div>
          </div>
        </div>
    </div>
  );
};

export default React.memo(Index);

