import { createReducer } from "@reduxjs/toolkit";
import {
  ADMIN_ALL_ORDERS_ERROR,
  ADMIN_ALL_ORDERS_FAIL,
  ADMIN_ALL_ORDERS_REQUEST,
  ADMIN_ALL_ORDERS_SUCCESS,
} from "../constant/Orders";

const initialState = {
  OrderLoading: false,
  OrderData: [],
};

export const AdminOrders = createReducer(initialState, (builder) => {
  builder
    .addCase(ADMIN_ALL_ORDERS_REQUEST, (state) => {
      state.cartLoading = true;
    })
    .addCase(ADMIN_ALL_ORDERS_FAIL, (state) => {
      state.cartLoading = false;
    })
    .addCase(ADMIN_ALL_ORDERS_SUCCESS, (state, action) => {
      state.cartLoading = false;
      state.OrderData = action.payload;
    })
    .addCase(ADMIN_ALL_ORDERS_ERROR, (state) => {
      state.cartLoading = false;
    })
   
});
