import React from 'react'

const SidebarSkeleton = () => {
    return (
        <div className="!w-[100%] h-[100px] relative rounded-lg  animate-pulse">
            <div className="h-[100%] w-[100%] bg-gray-200 rounded-md py-2 px-[10px] flex  gap-2  justify-between place-items-center">
                <div className="h-[100%] w-[100%] bg-gray-200 rounded-md  flex flex-col gap-2">
                    <div className="h-[30px] w-[150px] bg-gray-400 rounded-md px-[10px] "></div>
                    <div className="h-[25px] w-[180px] bg-gray-400 rounded-md px-[10px] "></div>
                    <div className="h-[15px] w-[80px] bg-gray-400 rounded-md px-[10px] "></div>
                </div>
                <div className="w-[150px] h-[70px] bg-red-200  rounded-lg"></div>
            </div>

        </div>
    )
}

export default SidebarSkeleton
