import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import './Skeleton.css'


const SkeletonLoading = () => {
    return (
        // <SkeletonTheme baseColor="#2c2c2c" highlightColor="gray"
        //     width={"100%"}
        //     height={"80vh"}
        // >
        //     <p>
        //         <Skeleton width={"100%"} height={"80vh"} borderRadius={16} duration={3} />
        //     </p>
        // </SkeletonTheme>
        <div className="w-[100%] h-[100%] p-4 rounded-lg  animate-pulse">
            <div className="grid grid-cols-2 gap-4 h-[100%] w-[100%] relative SkeletonLoading_carousal ">
                <div>
                    <div className="h-[100%] w-[100%] bg-gray-200 rounded-md SkeletonLoading_carousal_div01"></div>
                </div>
                <div className="text-white flex flex-col justify-between">
                    <div className='flex flex-col justify-center gap-3 h-[100%] relative'>
                        <div className="h-[30px] w-[30px] bg-gray-200 rounded absolute top-0 right-0"></div>
                        <div className="h-[40px] bg-gray-200 rounded mt-2 max-w-[100%]"></div>
                        <div className="h-[100px] bg-gray-200 rounded mt-2 max-w-[100%]"></div>
                        <div className='w-[100%] flex gap-2 flex-wrap'>
                            <div className="h-[30px] bg-gray-200 rounded mt-2 w-[130px]"></div>
                            <div className="h-[30px] bg-gray-200 rounded mt-2 w-[130px]"></div>
                            <div className="h-[30px] bg-gray-200 rounded mt-2 w-[130px]"></div>
                        </div>
                        <div className="h-10 max-w-[250px] bg-red-200  rounded-full mt-[20px]"></div>
                        <div className='w-[100%] flex gap-2 flex-wrap justify-end'>
                            <div className="h-[20px] bg-gray-200  mt-2 w-[20px] rounded-full"></div>
                            <div className="h-[20px] bg-gray-200  mt-2 w-[20px] rounded-full"></div>
                            <div className="h-[20px] bg-gray-200  mt-2 w-[20px] rounded-full"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonLoading
