import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../../setting/GlobleVariable";
import accountimage from "../../../assets/no_image_user.png";
import "./AccountDetails.css";
import { ReactSVG } from "react-svg";
import cameraSVG from "../../../assets/svg/profile.svg";
import {
  TokenVerify,
  UpdateUserPassword,
  UpdateUserProfile,
} from "../../../redux/actions/User";
import { Loading } from "notiflix";
import toast from "react-hot-toast";

const AccountDetails = () => {
  const user = useSelector((state) => state.user.user);
  const UserAvatar = ApiMediaURL + user?.Avatar;
  const [ProfileImage, setProfileImage] = useState(null);
  const [previewProfileImage, setPreviewProfileImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const [confirmPass, setconfirmPass] = useState("");

  useEffect(() => {
    setFirstName(user?.firstName ? user?.firstName : "");
    setlastName(user?.lastName ? user?.lastName : "");
    setMobileNumber(user?.phoneNumber ? user?.phoneNumber : "");
  }, [user]);

  const handleimagechange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageDataUrl = reader.result;
        setPreviewProfileImage(imageDataUrl);
        setProfileImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const dispatch = useDispatch();

  const UpdateProfile = async () => {
    if (ProfileImage) {
      const formdata = new FormData();
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("phoneNumber", MobileNumber);
      formdata.append("file", ProfileImage);
      await dispatch(UpdateUserProfile(formdata));
      await dispatch(TokenVerify());
    } else {
      const formdata = new FormData();
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("phoneNumber", MobileNumber);
      await dispatch(UpdateUserProfile(formdata));
      await dispatch(TokenVerify());
    }
  };
  const isLoading = useSelector((state) => state.user.isLoading);
  useEffect(() => {
    if (isLoading === true) {
      Loading.standard("Loading Plaese Wait", {
        // backgroundColor : "#CD292E"
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [isLoading]);

  const updatepass = () => {
    if (newPass !== confirmPass) {
      return toast.error("Conform Password Not Match");
    }
    if (!newPass || !currentPass || !confirmPass) {
      return toast.error("Please Enter All Password");
    }
    dispatch(UpdateUserPassword(currentPass, newPass));
  };

  return (
    <div className="py-2 px-2 bg-color1 text-color2 h-auto overflow-y-auto profile_AccountDetails">
      <h2 className="text-[25px] py-2 px-2 font-bold">Account Details.</h2>
      {/* --- profile image  */}
      <div className="w-[100%] my-3 flex justify-center place-items-center border-b-[1px] border-b-redColor pb-1">
        <div className="relative">
          <img
            src={
              previewProfileImage
                ? previewProfileImage
                : user && user?.Avatar
                ? UserAvatar
                : accountimage
            }
            alt=""
            className="object-cover w-[200px] h-[200px] rounded-full profile_AccountDetails_image"
          />
          <label htmlFor="getimage">
            <ReactSVG
              src={cameraSVG}
              className="absolute bottom-[10px] right-[20px] cursor-pointer text-color5 text-[35px] upload_image_profile"
            />
          </label>
          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            multiple={false}
            className="hidden"
            id="getimage"
            onChange={handleimagechange}
          />
        </div>
      </div>
      {/* ===== body  */}
      <div className="px-2 sm:px-5 flex flex-col gap-4">
        <h2 className="sm:text-[25px] text-[20px] py-2 px-2">
          General Information
        </h2>
        <div className="flex justify-start place-items-center gap-4 sm:flex-row flex-col">
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              First Name
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="John Doe"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              Last Name
            </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
              placeholder="Last Name"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
        </div>
        {/* ---  */}
        <div className="flex justify-start place-items-center gap-2 sm:flex-row flex-col">
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              Mobile Number
            </label>
            <input
              type="text"
              value={MobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Mobile Number"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              Email Address
            </label>
            <input
              type="text"
              readOnly
              value={user && user?.email}
              placeholder="example@example.com"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
        </div>
        <button
          className="px-5 my-2 w-[160px] rounded-md mx-2 py-2 bg-color1 border-[1px] text-color2 "
          onClick={UpdateProfile}
        >
          Update
        </button>
      </div>
      {/* ------- pasword inportmation  */}
      <div className="px-2 py-3 sm:px-5">
        <h2 className="sm:text-[25px] text-[20px] py-2 px-2">Password</h2>
        <div className="flex justify-start place-items-center gap-2 sm:flex-row flex-col">
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              Old password
            </label>
            <input
              type="password"
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
              placeholder="************"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
          <div className="w-full px-2 py-2">
            <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
              New password
            </label>
            <input
              type="password"
              value={newPass}
              onChange={(e) => setnewPass(e.target.value)}
              placeholder="************"
              className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
            />
          </div>
        </div>
        <div className="w-full px-2 py-2">
          <label htmlFor="" className="w-[100%] block mb-1 font-bold px-2">
            Confirm password
          </label>
          <input
            type="password"
            value={confirmPass}
            onChange={(e) => setconfirmPass(e.target.value)}
            placeholder="************"
            className="w-[100%] border-[1px] border-color4 bg-color4 outline-none px-3 py-2 !text-color2 rounded-md"
          />
        </div>
        <button
          className="px-5 my-2 w-[160px] rounded-md mx-2 py-2 bg-color1 border-[1px] text-color2 "
          onClick={updatepass}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AccountDetails;
