import React, { useEffect, useMemo, useState } from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Carousal01.css";
import { ReactSVG } from "react-svg";
import SkeletonLoading from "./Skeleton";
import { FaShareAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ClockSvg from "../../assets/svg/clock.svg";
import 'react-loading-skeleton/dist/skeleton.css';
import { Swiper, SwiperSlide } from "swiper/react";
import TicketSvg from "../../assets/svg/ticketsvg.svg";
import calenderSvg from "../../assets/svg/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import ShareModal from "../EventCard/LatestCard/ShareModal";
import locationSvg from "../../assets/svg/location-dot.svg";
import { GetAllCraousalEvent } from "../../redux/actions/Admin/AdminActions";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";


const Carousal01 = () => {

  const carousalData = useSelector((state) => state.Admin.carousalData);
  const isLoding = useSelector((state) => state.Admin.adminloading);
  const [Share, setShare] = useState(false);
  const [isFilterLoading, setisFilterLoading] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();

   useEffect(()=>{
      if(!isLoding){
        setTimeout(() => {
          setisFilterLoading(false)
        }, 1000);
      }
    },[carousalData , isLoding])

  useEffect(() => {
    if (carousalData?.length === 0) {
      dispatch(GetAllCraousalEvent());
    }
    // eslint-disable-next-line
  }, []);


  // handle navigation
  const handleNavigation = (eventId, dataToSend, image) => {
    navigate(`/tickets/group.details/${eventId}`, {
      state: { data: dataToSend, image: image },
    });
  };


  // handle share button
  const handleShare = (item) => {
    setShare(item);
  };

  // hanlde get ticket
  const handleGetTicket = (item) => {
    handleNavigation(
      item?.event[0]?.id,
      item?.event,
      `${ApiMediaURL}/${item?.image}`
    )
  };


  return (
    <>
      <div className={`Carousal01 w-full  rounded-[20px] h-[80vh]`}>
        {isLoding || isFilterLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{ delay: 3000 }}
              modules={[EffectFade, Navigation, Pagination, Autoplay]}
              className="w-[100%] h-[100%]"
            >
              {carousalData?.map((item, index) => {
                return (
                  <SwiperSlide
                    className="carousalbox bg-black z-10"
                    key={index}>
                    <div className="w-full h-full relative ">
                      <img
                        className="carousalbox_img_hero"
                        src={`${ApiMediaURL}/${item?.image}`}
                        alt=""
                      />
                      {
                        item?.isOverLay === true && (
                          <div
                            className="w-full h-full bg-gradient z-10 absolute top-0 left-0 opacity-[.8]"
                            style={{
                              background: `rgb(0,0,0)`,
                              backgroundImage: `linear-gradient(to right bottom, #e40434, #020202)`,
                            }}
                          ></div>
                        )}
                    </div>
                    <div className="carousalbox_content text-color2 relative  h-[100%]">
                      <div
                        className={`w-[250px] h-[250px] absolute z-[20] top-[-5px] right-[-5px]  opacity-[.9]`}
                        style={{
                          filter: `blur(70px)`,
                          background: `rgb(0,0,0)`,
                          backgroundImage: `linear-gradient(to top, #020202, 
                              #e40434 )`,
                        }}
                      ></div>
                      <div
                        className={`cursor-pointer w-[40px] h-[30px] absolute top-[15px] right-[10px] card_uper_vector1 z-[9999]`}
                      >
                        <FaShareAlt
                          className={`text-white z-40 text-[22px]`}
                          onClick={handleShare}
                        />
                      </div>
                      <h1
                        className={`text-[33px] font-[700] mb-[10px]`}
                        style={{
                          color: "#e40434",
                        }}
                      >
                        {
                          item?.event[0]?.name?.length > 21
                            ? `${item?.event[0]?.name.slice(0, 20)}...`
                            : item?.event[0]?.name}
                      </h1>
                      <p className="text-[18px] text-color2 pr-2">
                        {
                          item?.paragraph?.length > 100
                            ? `${item?.paragraph?.slice(0, 100)}...`
                            : item?.paragraph}
                      </p>
                      <div className="carousalbox_locations ">
                        <div className="carousalbox_locations_box">
                          <ReactSVG src={locationSvg} />
                          <p
                            className=""
                            style={{
                              color: "#e40434",
                            }}
                          >
                            {
                              item?.event[0]?.venue?.location &&
                                item?.event[0]?.venue?.location?.length > 16
                                ? `${item?.event[0]?.venue?.location?.slice(
                                  0,
                                  16
                                )}..`
                                : item?.event[0]?.venue?.location}
                          </p>
                        </div>
                        <div className="carousalbox_locations_box">
                          <ReactSVG src={calenderSvg} />
                          <p
                            className=""
                            style={{
                              color: "#e40434",
                            }}
                          >
                            {item &&
                              item?.event[0]?.occurs_at_local.slice(0, 10)}
                          </p>
                        </div>
                        <div className="carousalbox_locations_box">
                          <ReactSVG src={ClockSvg} />
                          <p
                            className=""
                            style={{
                              color: "#e40434",
                            }}
                          >
                            {item?.event[0]?.occurs_at_local
                              ? new Date(
                                item?.event[0]?.occurs_at_local
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                              : `------`}
                          </p>
                        </div>
                      </div>

                      <button
                        onClick={() => handleGetTicket(item)}
                        className="my-[22px] z-[9999999] carousal_content_btn flex justify-center place-items-center px-[80px] py-[15px] rounded-[10.33px] cursor-pointer gap-[8px]"
                        style={{
                          backgroundColor: "#e40434",
                        }}
                      >
                        <ReactSVG src={TicketSvg} />
                        <p className="text-[15.5px] font-[400px] text_option">
                          Get Tickets
                        </p>
                      </button>
                      {Share && (
                        <ShareModal
                          setShow={setShare}
                          item={item?.event && item?.event[0]}
                          Share={Share}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </div >
    </>
  );
};

export default React.memo(Carousal01);
