import React, { useState, useEffect } from "react";
import dropin from "braintree-web-drop-in";
import { ApiURL } from "../../setting/GlobleVariable";
import toast from "react-hot-toast";
import Spinner from "../Loading/Spinner";
import { useSelector } from "react-redux";

const ApplePay = ({ show, onPaymentCompleted, totalamount, setShow, createTevoClient }) => {
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [loading, setloading] = useState(false);
  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const initializeBraintree = async () => {
      if (show === true) {
        try {
          setloading(true);
          if (!user?.clientId) {
            var clientId = sessionStorage.getItem("client_id");
            if (!clientId) {
              await createTevoClient();
            }
            // get  client_id from session storage
          }
          const response = await fetch(`${ApiURL}/payment/generateClient/${user?.clientId || clientId}`, {
            method: "GET",
            headers: {
              token: localStorage.getItem("instapass_user_token"),
            },
          });
          const data = await response.json();
          if (response.status === 400 || response.status === 500) {
            return toast.error("Plaese Login to continue");
          }

          const { clientToken } = data;

          var instance = await dropin.create({
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            card: false,
            applePay: {
              paymentRequest: {
                total: {
                  label: "InstaPass",
                  amount:
                    singleCouponCode && singleCouponCode?.discount
                      ? totalamount - singleCouponCode?.discount <= 0
                        ? 0
                        : totalamount - singleCouponCode?.discount
                      : totalamount,
                },
              },
              // applePaySessionVersion: 3,
              currencyCode: "USD",
            },
          });
          setloading(false);
          setBraintreeInstance(instance);

          instance.on("paymentMethodRequestable", (event) => {
            instance.requestPaymentMethod((err, payload) => {
              if (err) {
                console.error(err, "error from payap");
                // toast.error(err.message || "Something went wrong")
                return;
              }
              const nonce = payload.nonce;
              // console.log(nonce, "nonace from paypal");
              handlePayment(nonce);
            });
          });
        } catch (error) {
          console.error("Error initializing Braintree:", error);
          toast.error(error.message || "Something went wrong")

        }
      }
    };

    if (show === true) {
      initializeBraintree();
    } else {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    }

    // return () => {
    //   if (braintreeInstance) {
    //     braintreeInstance.teardown().then(() => setBraintreeInstance(null));
    //   }
    // };
  }, [show, onPaymentCompleted]);

  const handlePayment = async (nonce) => {
    onPaymentCompleted({
      payment_method_nonce: nonce,
      paymentType: "ApplePay",
      paymentMethod: 'BraintreeApplePay'
    });
  };

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id="braintree-drop-in-div" />
      {loading && (
        <div className="w-full flex justify-center place-items-center py-2 px-2">
          <Spinner />
        </div>
      )}
      <p>{loading}</p>
    </div>
  );
};

export default ApplePay;
