// import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// import all routes in lazy loaded routes
// const Home = lazy(() => import("../pages/Home"));
// const About = lazy(() => import("../pages/About"));
// const Tickets = lazy(() => import("../pages/Tickets"));
// const Index = lazy(() => import("../DashBoard/Index"));
// const Checkout = lazy(() => import("../pages/Checkout"));
// const NotFound = lazy(() => import("../pages/NotFound"));
// const Category = lazy(() => import("../pages/Category"));
// const NewsFeeds = lazy(() => import("../pages/NewsFeeds"));
// const Conditions = lazy(() => import("../pages/Conditions"));
// const UserProfile = lazy(() => import("../pages/UserProfile"));
// const Login = lazy(() => import("../Components/accounts/Login"));
// const GroupDetails = lazy(() => import("../pages/GroupDetails"));
// const Registration = lazy(() => import("../pages/Registration"));
// const PrivicyPolicy = lazy(() => import("../pages/PrivicyPolicy"));
// const NewPaymentPage = lazy(() => import("../pages/NewPaymentPage"));
// const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
// const DownloadInvoice = lazy(() => import("../pages/DownloadInvoice"));



import  Home  from "../pages/Home";
import  About  from "../pages/About";
import  Tickets  from "../pages/Tickets";
import  Index  from "../DashBoard/Index";
import  Checkout  from "../pages/Checkout";
import  NotFound  from "../pages/NotFound";
import  Category  from "../pages/Category";
import  NewsFeeds  from "../pages/NewsFeeds";
import  Conditions  from "../pages/Conditions";
import  UserProfile  from "../pages/UserProfile";
import  Login  from "../Components/accounts/Login";
import  GroupDetails  from "../pages/GroupDetails";
import  Registration  from "../pages/Registration";
import  PrivicyPolicy  from "../pages/PrivicyPolicy";
import  NewPaymentPage  from "../pages/NewPaymentPage";
import  ForgotPassword  from "../pages/ForgotPassword";
import  DownloadInvoice  from "../pages/DownloadInvoice";




export default function RouterComponent({ user }) {

    return (
        // <Suspense fallback={<div className="w-[100%] h-[100vh] flex justify-center place-items-center text-white text-2xl">Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/*" element={<NotFound />} />
                <Route path="/login" element={<Login />} />
                <Route path="/aboutUs" element={<About />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/tickets/:name" element={<Category />} />
                <Route path="/newsfeed/:id" element={<NewsFeeds />} />
                <Route path="/payment" element={<NewPaymentPage />} />
                <Route path="/user/profile" element={<UserProfile />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/privacyPolicy" element={<PrivicyPolicy />} />
                <Route path="/terms&conditions" element={<Conditions />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/tickets/group.details/:id" element={<GroupDetails />} />
                {user?.role === "admin" && <Route path="/admin/dashboard" element={<Index />} />}
                <Route path="/invoice/download/:orderId/:itemId" element={<DownloadInvoice />} />
                <Route path="/tickets/group.details/:id/:loc/:date/:time/:name" element={<GroupDetails />} />
            </Routes>
        // </Suspense>
    );
}
