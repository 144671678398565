import React, { useMemo } from 'react'
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperBreakPoint } from '../../staticData/StaticData';
import CustomSliderbtn from '../Home/LatestEvent/CustomSliderbtn';
const LatestLoading = ({ isHome = false, swiperRef ,goNext , goPrev }) => {
    const breakPointsValues = useMemo(() => SwiperBreakPoint, []);
    return (

        <>
            {
                !isHome ?
                    <div className="!w-[300px] h-[410px] relative rounded-lg  animate-pulse">
                        <div className="h-[100%] w-[100%] bg-gray-200 rounded-md py-4 px-[10px] flex flex-col gap-2">
                            <div className="h-[300px] w-[100%] bg-gray-300 rounded-md relative">
                                <div className="w-[70px] h-[40px] bg-gray-400 rounded-md absolute top-2 right-2 z-10"></div>
                                <div className="w-[100%] min-h-[40px] bg-gray-300 rounded-md absolute bottom-0 left-0 z-10 flex flex-col gap-2 px-2">
                                    <div className="w-[100%] h-[100px] bg-gray-400 rounded-md"></div>
                                    <div className="w-[80px] h-[30px] bg-gray-400 rounded-md"></div>
                                    <div className="w-[80%] h-[30px] bg-gray-400 rounded-md mb-2"></div>
                                </div>

                            </div>
                            <div className="w-[100%] h-[70px] bg-gray-300 rounded-md px-2 flex justify-start place-items-center">
                                <div className="w-[130px] h-[40px] bg-red-200  rounded-full"></div>
                                <div className="w-[100%] h-[40px] bg-gray-300 rounded ml-2">
                                    <div className="w-[90%] h-[20px] bg-gray-400 rounded-md"></div>
                                    <div className="w-[100%] h-[20px] bg-gray-400 rounded-md mt-1"></div>
                                </div>
                            </div>

                        </div>

                    </div> :
                    <div className='flex flex-col gap-1 max-w-[2000px] overflow-x-hidden mx-auto'>
                        {[0, 1, 2].map((i) => {
                            return (
                                <>
                                    <CustomSliderbtn prev={() => goPrev(i)} next={() => goNext(i)} />
                                    <Swiper
                                        ref={swiperRef[i]}
                                        key={i}
                                        slidesPerView={4}
                                        spaceBetween={0}
                                        freeMode={true}
                                        breakpoints={breakPointsValues}
                                        className="mySwiper my-2 mx-auto 2xl:gap-[30px] pt-1 pb-3"
                                        lazyPreloadPrevNext={true}
                                    >
                                        {[1, , 2, 3, 4, 5, 6].map((item) => <SwiperSlide key={item} className='!max-w-[300px]'>
                                            <div className="!w-[100%] h-[410px] relative rounded-lg  animate-pulse">
                                                <div className="h-[100%] w-[100%] bg-gray-200 rounded-md py-4 px-[10px] flex flex-col gap-2">
                                                    <div className="h-[300px] w-[100%] bg-gray-300 rounded-md relative">
                                                        <div className="w-[70px] h-[40px] bg-gray-400 rounded-md absolute top-2 right-2 z-10"></div>
                                                        <div className="w-[100%] min-h-[40px] bg-gray-300 rounded-md absolute bottom-0 left-0 z-10 flex flex-col gap-2 px-2">
                                                            <div className="w-[100%] h-[100px] bg-gray-400 rounded-md"></div>
                                                            <div className="w-[80px] h-[30px] bg-gray-400 rounded-md"></div>
                                                            <div className="w-[80%] h-[30px] bg-gray-400 rounded-md mb-2"></div>
                                                        </div>

                                                    </div>
                                                    <div className="w-[100%] h-[70px] bg-gray-300 rounded-md px-2 flex justify-start place-items-center">
                                                        <div className="w-[130px] h-[40px] bg-red-200  rounded-full"></div>
                                                        <div className="w-[100%] h-[40px] bg-gray-300 rounded ml-2">
                                                            <div className="w-[90%] h-[20px] bg-gray-400 rounded-md"></div>
                                                            <div className="w-[100%] h-[20px] bg-gray-400 rounded-md mt-1"></div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </SwiperSlide>)
                                        }
                                    </Swiper>
                                </>
                            )
                        })}
                    </div>
            }
        </>

    )
}

export default LatestLoading
