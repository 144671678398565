import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { CreateEventByAdmin, CreateEventByAdminFeatured } from '../../../redux/actions/Admin/AdminActions';
import toast from 'react-hot-toast';

const CreateEvent = ({ event, setEvent, setcreateevent }) => {
    const [modal, setModal] = useState(1);
    const [Paragraph, setParagraph] = useState("");
    const [image, setImage] = useState(null);

    const dispatch = useDispatch();


    const uploadImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(file);
            };
            reader.readAsDataURL(file);
        }
    };



    const createeventFunction = async () => {
        if (modal === 1) {
            if (!Paragraph || !event) {
                return toast.error("Please Enter All fields");
            }
            const formdata = new FormData();
            const url = "create/feature";
            formdata.append("event", JSON.stringify(event));
            formdata.append("paragarph", Paragraph);
            formdata.append("isFeatured", true);
            await dispatch(CreateEventByAdminFeatured(formdata, url));
            setEvent({});
            setParagraph("");
            setcreateevent(false);
        } else {
            if (!Paragraph || !event || !image) {
                return toast.error("Please Enter All fields");
            }
            const formdata = new FormData();
            const url = "create";
            formdata.append("event", JSON.stringify(event));
            formdata.append("paragarph", Paragraph);
            formdata.append("file", image);
            formdata.append("isFeatured", false);
            await dispatch(CreateEventByAdmin(formdata, url));
            setEvent({});
            setParagraph("");
            setImage(null);
            setcreateevent(false);
        }
    };







    return (
        <div className="z-[999999] fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
            <div className="max-h-full relative w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4">
                <div className="w-full">
                    <div className="py-16 px-5">
                        <div className="">
                            <RxCross1
                                className="absolute top-4 right-4 text-[25px] cursor-pointer"
                                onClick={() => {
                                    setcreateevent(false);
                                    setEvent({});
                                    setParagraph("");
                                    setImage(null);
                                }}
                            />

                            {/* --------- paragrapg  */}
                            <div className="w-[100%] my-5">
                                <label
                                    htmlFor="heading"
                                    className="w-full block mb-1 pl-1 "
                                >
                                    Carousal Paragraph
                                </label>
                                <input
                                    value={Paragraph}
                                    onChange={(e) => setParagraph(e.target.value)}
                                    type="text"
                                    placeholder="Please Enter Paragraph"
                                    className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                                />
                            </div>
                            {/* ------ image  */}
                            {modal === 2 && (
                                <div className="w-[100%] my-5">
                                    <label
                                        htmlFor="heading"
                                        className="w-full block mb-1 pl-1 "
                                    >
                                        Carousal Image
                                    </label>
                                    <input
                                        onChange={uploadImageChange}
                                        type="file"
                                        accept="image/*"
                                        multiple="fase"
                                        placeholder="Please Enter Paragraph"
                                        className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                                    />
                                </div>
                            )}
                            <button
                                className="mt-2 px-3 py-3 bg-color1 text-color2 w-full text-[17px] rounded-md"
                                onClick={createeventFunction}
                            >
                                Create
                            </button>
                            <p
                                className="cursor-pointer mt-5 text-center text-[gray]"
                                onClick={() => {
                                    if (modal === 1) {
                                        setModal(2);
                                    } else {
                                        setModal(1);
                                    }
                                }}
                            >
                                create {modal === 2 ? "Latest Event" : "Trending Event"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEvent
