import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { ReactSVG } from "react-svg";
import crossSvg from "../../../../assets/svg/cross.svg";
import Loginbox from "../../../accounts/Loginbox";
import SignupBox from "../../../accounts/SignupBox";
import { useNavigate, useParams } from "react-router-dom";


const CheckOutSidebar = ({
  SingleTicket,
  SetSingleTicket,
  filterevent,

}) => {

  const params = useParams();

  const [qty, setqty] = useState(null);
  const [isPopupLogin, setIsPopupLogin] = useState(false)
  const [active, setActive] = useState(1);

  const PaymentDetails = useSelector((state) => state.events.TaxData);
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate()

  useEffect(() => {
    if (
      isloadingcreateOrder === true &&
      SingleTicket &&
      SingleTicket?.length > 0
    ) {
      Loading.standard("Loading Please Wait");
    } else {
      Loading.remove();
    }
  }, [isloadingcreateOrder]);

  const HandleIsPayment = (isLogin = false) => {
    if (isLogin) {
      return setIsPopupLogin(true)
    }
    if (!qty) {
      return toast.error("Select the quantity");
    }
    const sendorderdata = {
      id: SingleTicket && SingleTicket[0]?.id,
      qty,
      retail_price: SingleTicket && SingleTicket[0]?.retail_price,
      format: SingleTicket && SingleTicket[0]?.format,
      service_fee: SingleTicket && SingleTicket[0]?.service_fee,
      tax: PaymentDetails?.retail?.tax,
      wholesale_price : SingleTicket && SingleTicket[0]?.wholesale_price,
    };
    navigate("/checkout",
      {
        state: {
          filterevent: filterevent,
          data : sendorderdata,
          id : params?.id
        }
      }
    )
  }


  return (
    <>
      <div
        className={`py-5 px-3 !w-[390px] absolute top-0 z-10 !bg-color1 h-[100vh] !bg-transparent  !text-color2  checkout_sidebar_inner
      ${SingleTicket && SingleTicket?.length > 0
            ? "left-[0%] duration-500"
            : "left-[-150%] duration-500"
          }
            flex flex-col
    `}
      >
        <div className="flex justify-between place-items-start flex-col gap-0 mt-5">
          <div className="flex justify-between place-items-start w-full checkoutsidebar_header">
            <div>
              <h2 className="mb-1">
                Section {SingleTicket && SingleTicket[0]?.section} - Row{" "}
                {SingleTicket && SingleTicket[0]?.row}
              </h2>
              <p className="my-0 font-bold">
                ${SingleTicket && SingleTicket[0]?.retail_price}/ea
              </p>
            </div>

            <ReactSVG
              src={crossSvg}
              className="mt-1 mx-2 text-[25px] cursor-pointer"
              accordion
              onClick={() => SetSingleTicket([])}
            />
          </div>
          <div className="flex justify-end my-1 place-items-center w-full">
            <select
              className="!w-[200px] bg-transparent outline-none !text-color2 py-2 px-5 !bg-color1  rounded-[12px] border-color5 border-[1px] border-shadowColor"
              value={qty}
              onChange={(e) => setqty(e.target.value)}
            >
              <option value="" className="">
                Select Ticket
              </option>
              {SingleTicket &&
                SingleTicket[0]?.splits?.map((item, index) => {
                  return (
                    <option
                      value={item && item}
                      key={index}
                      className="!border-b-[1px] border-b-color5"
                    >
                      {item && item}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>



        {
          qty &&
          <div className="flex-1 flex justify-center flex-col mb-[80px] ">
            <div
              className={`paymentmethodboxchild border-[1px] border-shadowColor bg-redColor mt-[30px] mb-[20px]`}
              onClick={() => {
                const isLogin = false;
                HandleIsPayment(isLogin)
              }}
            >
              {/* <ReactSVG src={CreditSvg} /> */}
              <p>{user?.email ? "Continue Payment" : "Guest Checkout"}</p>
            </div>
            {
              !user?.email && <p className="text-center text-[20px] opacity-[.5] OR_Text font-semibold">OR</p>
            }
            {
              !user?.email && <div
                className={`paymentmethodboxchild border-[1px] border-shadowColor  mt-[20px]`}
                onClick={() => {
                  const isLogin = true;
                  HandleIsPayment(isLogin)
                }}
              >
                <p>Login To Continue</p>
              </div>
            }
          </div>
        }
      </div>

      {/* =============  */}
      {
        isPopupLogin &&
        <div
          class="fixed  inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.7)] overflow-auto z-[999]"
        >
          {
            active === 1 ?
              <div className="w-[100%] flex justify-center place-items-center z-[999999999999999]">
                <Loginbox
                  isModalLogin={true}
                  setModalShow={setIsPopupLogin}
                  setActive={setActive}
                />
              </div>
              :
              <div className="w-[100%] flex justify-center place-items-center z-[999999999999999]">
                <SignupBox
                  isModalLogin={true}
                  setModalShow={setIsPopupLogin}
                  setActive={setActive}
                />
              </div>
          }

        </div>
      }

    </>
  );
};

export default CheckOutSidebar;