import React, { useEffect, useMemo, useRef, useState } from "react";
import "swiper/css";
import "./styles/styles.css";
import "swiper/css/pagination";
import "./styles/trendingEvent.css";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoIosArrowForward } from "react-icons/io";
import NoDataMessag01 from "../../NoDataMessage/NoDataMessag01";
import TrendingCard from "../../EventCard/TrendingCard/TrendingCard";
import TrendingSkeleton from "../../Loading/TrendingSkeleton";
import { SwiperBreakPoint } from "../../../staticData/StaticData";

const TrendingEvent = () => {
  const swiperRef = useRef(null);
  const FeaturedEventsdata = useSelector((state) => state.Admin.featuredEvents);
  const isLoading = useSelector((state) => state.Admin.adminloading);
  const [isFilterLoading, setisFilterLoading] = useState(true)

  const filterTrndingEvent = useMemo(() => FeaturedEventsdata?.filter(item => item.isFeatured === false)
    , [FeaturedEventsdata])

  useEffect(()=>{
    if(!isLoading){
      setTimeout(() => {
        setisFilterLoading(false)
      }, 2000);
    }
  },[filterTrndingEvent , isLoading])


  const goNext = () => {
    if (!swiperRef?.current?.swiper) return;
    swiperRef.current.swiper.slideNext();

  };

  const goPrev = () => {
    if (!swiperRef?.current?.swiper) return;
    swiperRef.current.swiper.slidePrev();

  };

  const breakPointsValues = useMemo(() => SwiperBreakPoint, []);

  return (
    <>
      <div className="trendingEvent relative text-color2 bg-color1">
        <h2 className="text-redColor text-center text-[38px] font-[600] mb-[50px] tracking-[15px] t_h">
          Trending live events
        </h2>
        {
          isLoading || isFilterLoading ? <TrendingSkeleton /> :
            filterTrndingEvent?.length === 0 && !isLoading ? <NoDataMessag01 value="Trending Events" /> :
              <>
                <div className="w-full flex gap-3 justify-end place-items-end px-2">
                  <div
                    className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
                    onClick={goPrev}
                  >
                    <IoIosArrowBack className="text-[23px] hover:text-redColor" />
                  </div>
                  <div
                    className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
                    onClick={goNext}
                  >
                    <IoIosArrowForward className="text-[23px] hover:text-redColor" />
                  </div>
                </div>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={4}
                  spaceBetween={0}
                  freeMode={true}
                  breakpoints={breakPointsValues}
                  className="mySwiper my-2 mx-auto 2xl:gap-[30px] pt-1 pb-3"
                  lazyPreloadPrevNext={true}
                >
                  {

                    filterTrndingEvent &&
                    filterTrndingEvent?.map((item, index) => {
                      return (
                        <SwiperSlide
                          className="shrink-0 !flex !justify-center !place-items-center  !h-[400px]"
                          key={index}
                        >
                          <TrendingCard item={item} />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </>

        }
      </div>
    </>
  );
};

export default TrendingEvent;
