import React, { memo, useMemo } from 'react';
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import FeaturedEvent from '../../EventCard/LatestCard/FeaturedEvent';
import { SwiperBreakPoint } from '../../../staticData/StaticData';

const EventCarosal = ({ swiperRef, events }) => {

  const breakPointsValues = useMemo(() => SwiperBreakPoint, []);

  return (
    <Swiper
      ref={swiperRef}
      slidesPerView={4}
      spaceBetween={0}
      freeMode={true}
      breakpoints={breakPointsValues}
      lazyPreloadPrevNext={true}
      className="flex justify-start place-items-center gap-[20px] flex-nowrap  mx-auto 2xl:gap-[30px] my-2 "
    >
      {
        events?.map((item, index) => {
          return (
            <SwiperSlide className="shrink-0 !flex !justify-center !place-items-center" key={index}>
              <FeaturedEvent
                item={item?.event[0]}
                index={index + 1}
              />
            </SwiperSlide>
          );
        })}
    </Swiper>
  )
}

export default memo(EventCarosal)
