import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckOutSidebar from "./CheckOutSidebar";
import { ReactSVG } from "react-svg";
import locationSVG from "../../../../assets/svg/location-dot.svg";
import CalenderSVG from "../../../../assets/svg/calendar.svg";
import ClockSVG from "../../../../assets/svg/clock.svg";
import DollarSVG from "../../../../assets/svg/circle-dollar1.svg";
import TicketSVG from "../../../../assets/svg/Ticket Icon.svg";
import bookingSVG from "../../../../assets/svg/Booking Icon.svg";
import Loading from "../../../Loading/Loading";
import { useParams } from "react-router-dom";
import $ from "jquery";
import Seatics from "../Seatics";
import SidebarSkeleton from "../../../Loading/SidebarSkeleton";
import moment from "moment";

const Sidebar = ({
  filterevent,
  receivedData,
  isImage,
  noChart,
  reviceImage,
  SeaticsData,
  eventId,
  setSeaticsData,
}) => {
  const [active, setActive] = useState(1);
  const [SingleTicket, setSingleTicket] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const GroData = useSelector((state) => state.events.EventDetails);

  // const GroupsEvents = eventId === 2609633 ? SeaticsData :GroData;
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  const SibgleEventLoading = useSelector((state) => state.events.isLoading);

  // const [GroupsEvents, setGroupsEvents] = useState(
  //   eventId == "2710569" ? SeaticsData : GroData
  // );
  const [GroupsEvents, setGroupsEvents] = useState(SeaticsData);


  const params = useParams();

  const clickOnButton = (id) => {
    const filterSingleEvent =
      // eslint-disable-next-line
      GroData && GroData?.filter((item) => item.id == id);
    setSingleTicket(filterSingleEvent);
  };

  const TypeParking =
    GroupsEvents && GroupsEvents?.filter((item) => item.type === "parking");

  useEffect(() => {
    // setGroupsEvents(eventId == "2710569" ? SeaticsData : GroData);
    setGroupsEvents(SeaticsData);
  }, [GroData, SeaticsData, eventId]);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);




  return (
    <>
      <div className="!bg-transparent !bg-black  relative py-5 px-1">
        {/* --------------------  */}
        <div
          className={` ${SingleTicket && SingleTicket?.length > 0
            ? "left-[0%] duration-500"
            : "!left-[-150%] duration-500"
            }  `}
        >
          <CheckOutSidebar
            SingleTicket={SingleTicket || receivedData}
            SetSingleTicket={setSingleTicket}
            filterevent={filterevent?.length === 1 ? filterevent : receivedData}
          />
        </div>
        {/* --head  */}
        {SingleTicket && SingleTicket?.length === 0 && (
          <div className="deatils_sidebar">
            <div className=" px-3">
              <img
                src={reviceImage}
                alt=""
                className="my-[10px] rounded-md w-[100%] max-h-[300px]"
              />
              <h2 className="mb-1 text-[23px] text-color2 font-medium evet_namesidebar">
                {(filterevent && filterevent[0]?.name) ||
                  (receivedData && receivedData[0]?.name) ||
                  params?.name}
              </h2>
              <div className="flex justify-start place-items-center gap-[10px] my-3 flex-wrap">
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={locationSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.venue?.location) ||
                      (receivedData && receivedData[0]?.venue?.location) ||
                      params?.loc}
                  </span>
                </div>
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={CalenderSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.occurs_at?.slice(0, 10)) ||
                      (receivedData &&
                        receivedData[0]?.occurs_at?.slice(0, 10)) ||
                      params?.date?.slice(0, 10)}
                  </span>
                </div>

                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={ClockSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.occurs_at &&
                      moment(filterevent[0]?.occurs_at)
                        .utcOffset("+01:00")
                        .format("h:mm A")
                        ?.split("+", 1)) ||
                      (
                        receivedData && receivedData[0]?.occurs_at &&
                        moment(receivedData[0]?.occurs_at)
                          .utcOffset("+01:00")
                          .format("h:mm A")
                          ?.split("+", 1))}
                  </span>
                </div>
              </div>
            </div>
            {/* ----- image   */}
            <div
              className={`parent_seat_map_wraper bg-color1 siebar_image_div my-3 `}
            >
              {AllEventLoading ? (
                <div>
                  <Loading />
                </div>
              ) :
                // (filterevent &&
                //   // eslint-disable-next-line
                //   filterevent[0]?.configuration?.seating_chart?.large ==
                //   "null") ||
                //   (receivedData &&
                //     // eslint-disable-next-line
                //     receivedData[0]?.configuration?.seating_chart?.large ==
                //     "null") ? (
                //   <img src={noChart} alt="no seating chart" className=" h-[300px]" />
                // ) : (
                // eventId == '2710569' ?
                windowWidth <= 550 && (
                  <Seatics
                    setSidebarData={setSeaticsData}
                    GroupsEvents={GroupsEvents}
                    SeaticsData={SeaticsData}
                  />
                )
                // :
                // <img
                //   src={isImage}
                //   alt="Seating Chart"
                //   className=" h-[300px] object-contain bg-color1 bg-opacity-0"
                // />
                // )
              }
            </div>

            {/* ------  */}
            {SibgleEventLoading ||
              GroupsEvents?.length === 0 
              // ||
              // (SeaticsData?.length === 0) 
              ? (
              <div className="sidebrticket_box px-2 py-2 rounded-md flex flex-col gap-3">
                {/* <Spinner /> */}
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ].map((i) => {
                  return <SidebarSkeleton />;
                })}
              </div>
            ) : (
              <div className=" py-2 px-2 mb-[90px]">
                <div className="flex justify-around place-items-center gap-2 px-[10px] my-2">
                  <h2
                    className={` mb-2 font-medium allticket_sidebar_text cursor-pointer ${active === 1
                      ? "!text-redColor border-b-[1px] border-b-redColor pb-1"
                      : "!text-color5"
                      }`}
                    onClick={() => setActive(1)}
                  >
                    All Tickets
                  </h2>
                  <h2
                    className={`text-color5 mb-2 font-medium allticket_sidebar_text cursor-pointer ${active === 2
                      ? "!text-redColor border-b-[1px] border-b-redColor pb-1"
                      : "!text-color5"
                      }`}
                    onClick={() => setActive(2)}
                  >
                    Parking
                  </h2>
                </div>
                <div className=" px-2 py-2 rounded-md sidebrticket_box">
                  {/* ---- price  */}
                  {GroupsEvents && active === 1 ? (
                    GroupsEvents?.map((item, index) => {
                      if (item?.type === "parking") {
                        return;
                      }
                      // if (eventId == "2710569") {
                        return (
                          <div
                            key={index}
                            className="ticket-item py-2 px-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-start cursor-pointer hover:border-[1px] hover:border-redColor  "
                            // onClick={() => { clickOnButton(item?.tgID) }}
                            onClick={() => {
                              clickOnButton(item?.tgID);
                              window.Seatics.MapComponent.toggleSectionSelection(
                                item?.tgUserSec,
                                true
                              );
                            }}
                            style={{
                              borderLeft: `8px solid  ${item?.section?.level?.color}`,
                            }}
                            onMouseEnter={() => {
                              $(".ticket-item").hover(function () {
                                const tgId = item?.tgID;
                                const ticketGroup =
                                  window.Seatics.MapComponent.getTgFromId(tgId);
                                if (ticketGroup) {
                                  window.Seatics.MapComponent.highlightTicket(
                                    ticketGroup
                                  );
                                }
                              });
                            }}
                          >
                            <div className="w-full">
                              <h2 className="mb-1 font-bold sidebar_ticket_name">
                                Section {item?.tgUserSec || item?.section}
                              </h2>
                              <h2 className="mb-1 !font-[500] sidebar_ticket_name">
                                Row : {item?.row || item?.tgUserRow} |{" "}
                                {item?.available_quantity || item?.tgQty}{" "}
                                tickets
                              </h2>
                              <div className="flex justify-between place-items-center gap-1">
                                <p className="text-color5 ticket_available_count !text-[14px]">
                                  {item?.tgCType}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="bg_linear flex flex-col text-white px-2 py-1 rounded-md min-w-[100px] text-[18px] text-[600] text-center">
                                ${item?.tgPrice}/ea
                                <p className="text-[11px] font-[400]">
                                  ALL-IN NO FEES
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      // } else {
                        // return (
                        //   <div
                        //     key={index}
                        //     className="ticket-item py-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-center cursor-pointer hover:border-[1px] hover:border-redColor px-1 "
                        //     onClick={() => {
                        //       clickOnButton(item?.id);
                        //     }}
                        //   >
                        //     <div className={``}>
                        //       <h2 className="px-1 sidebar_price_tikcet py-1  rounded-md font-medium text-[18px]">
                        //         <span>
                        //           <ReactSVG src={DollarSVG} />
                        //         </span>
                        //         {item?.retail_price}
                        //       </h2>
                        //     </div>
                        //     <div className="w-full">
                        //       <h2 className="mb-1 font-bold sidebar_ticket_name">
                        //         Section {item?.section} - Row {item?.row}{" "}
                        //       </h2>
                        //       <div className="flex justify-between place-items-center gap-1">
                        //         <p className="text-color5 ticket_available_count !text-[14px]">
                        //           {item?.available_quantity} tickets
                        //           <span>
                        //             <ReactSVG src={TicketSVG} />
                        //           </span>
                        //         </p>
                        //         <p className="text-color5 ticket_available_count !text-[14px]">
                        //           {item?.format}
                        //         </p>
                        //       </div>
                        //       <p className="text-color5 ticket_available_count !text-[14px] border-t-[1px]">
                        //         {item?.public_notes}
                        //       </p>
                        //     </div>
                        //     {/* <IoIosArrowForward className="text-[35px] text-color5" /> */}
                        //     <div>
                        //       <ReactSVG src={bookingSVG} />
                        //     </div>
                        //   </div>
                        // );
                      // }
                    })
                  ) : TypeParking?.length > 0 ? (
                    TypeParking?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="py-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-center cursor-pointer hover:border-[1px] hover:border-redColor px-1 "
                          onClick={() => clickOnButton(item?.id)}
                        >
                          <h2 className="px-1 sidebar_price_tikcet py-1  rounded-md font-medium text-[18px]">
                            <span>
                              <ReactSVG src={DollarSVG} />
                            </span>
                            {item?.retail_price || item?.tgPrice}
                          </h2>
                          <div className="w-full">
                            <h2 className="mb-1 font-bold sidebar_ticket_name">
                              Section {item?.tgUserSec || item?.section} - Row{" "}
                              {item?.tgUserRow || item?.row}{" "}
                            </h2>
                            <p className="text-color5 ticket_available_count">
                              {item?.available_quantity || item?.tgQty} tickets
                              <span>
                                <ReactSVG src={TicketSVG} />
                              </span>
                            </p>
                          </div>
                          {/* <IoIosArrowForward className="text-[35px] text-color5" /> */}
                          <div>
                            <ReactSVG src={bookingSVG} />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center my-3 text-[15px]">
                      No parking available
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
